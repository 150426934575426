<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br /><br />

      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br />
        <br />
        <h3>FICHA AMBULATORIAL DA MULHER</h3>
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>

          {{ elemento }}
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    if (this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];

    if (this.laudo.queixas) folha.push(`Queixas: ${this.laudo.queixas}`);

    if (this.laudo.Ap) folha.push(`AP: ${this.laudo.Ap}`);

    if (this.laudo.Af) folha.push(`AF: ${this.laudo.Af}`);

    if (this.laudo.medicacoes) folha.push(`${this.laudo.medicacoes}`);

    if (this.laudo.Alergias) folha.push(`Alergias: ${this.laudo.Alergias}`);

    if (this.laudo.cirurgia) folha.push(`Cirurgia: ${this.laudo.cirurgia}`);

    if (this.laudo.dataDum) folha.push(`DUM: ${this.laudo.dataDum}`);

    if (this.laudo.colpocitopatologiaOntotica)
      folha.push(
        `Última Colpocitopatologia Oncótica: ${this.laudo.colpocitopatologiaOntotica}.`
      );

    if (this.laudo.sexarca) folha.push(`Sexarca: ${this.laudo.sexarca}.`);

    if (this.laudo.menarca) folha.push(`Menarca: ${this.laudo.menarca}.`);

    if (this.laudo.procedimentoEmColo)
      folha.push(`${this.laudo.procedimentoEmColo}.`);

    if (this.laudo.g)
      folha.push(`G: ${this.laudo.g} P: ${this.laudo.p} A: ${this.laudo.a}.`);

    if (this.laudo.passadoObstetrico)
      folha.push(`${this.laudo.passadoObstetrico}.`);

    if (this.laudo.corrimento)
      folha.push(`Corrimento: ${this.laudo.corrimento}.`);

    if (this.laudo.dispareunia)
      folha.push(`Dispareunia: ${this.laudo.dispareunia}.`);

    if (this.laudo.sinosorragia)
      folha.push(`Sinosorragia: ${this.laudo.sinosorragia}.`);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.parteUm = [...folha];
    folha = [];

    if (this.laudo.colo) folha.push(`Colo: ${this.laudo.colo}.`);

    if (this.laudo.conteudo) folha.push(`${this.laudo.conteudo}.`);

    if (this.laudo.pa) folha.push(`PA: ${this.laudo.pa}.`);

    if (this.laudo.peso) folha.push(`Peso: ${this.laudo.peso}.`);

    if (this.laudo.afu) folha.push(`AFU: ${this.laudo.afu}.`);

    if (this.laudo.bcf) folha.push(`BCF: ${this.laudo.bcf}.`);

    if (this.laudo.hd) folha.push(`HD: ${this.laudo.hd}.`);

    if (this.laudo.solicitado) folha.push(`${this.laudo.solicitado}.`);

    if (this.laudo.conduta) folha.push(`${this.laudo.conduta}.`);

    if (folha.length > 0) folha.splice(0, 0, 'AO EXAME ESPECULAR');
    this.especulacao = [...folha];
    folha = [];

    const todos = [...this.parteUm, ...this.especulacao];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 30));
    }
  },

  data() {
    return {
      parteUm: [],
      especulacao: [],
      via: '',
      tamanhoElemento: [],
      titulos: ['parteUm', 'AO EXAME ESPECULAR'],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: -5px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: 0px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: 0px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
  footer {
    // position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
